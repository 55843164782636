import {createAction, props} from "@ngrx/store";

export const cognitoLoginActionTypes = {
    successfulLogin: "SuccessfulCognitoLogin"
};

export const successfulCognitoLoginAction = createAction(
    cognitoLoginActionTypes.successfulLogin,
    props<{ username: string; email: string, cognitoToken: string }>()
);
