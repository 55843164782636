import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Auth } from "@aws-amplify/auth";
import { environment } from "../../../environments/environment";
import { successfulCognitoLoginAction } from "../../store/root/cognito-creds/cognito-creds.actions";
import { BaseHttpService } from './base.abstract';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenResponse } from '../types/token.model';

@Injectable({
    providedIn: "root"
})
export class AuthService extends BaseHttpService {

  constructor(private store: Store, _httpClient: HttpClient) {
    super(_httpClient, `${environment.rootAuthnUrl}`)
  }

    public async checkUserLoginStatus(forceLogin = true, forceSSO = true): Promise<boolean> {
        try {
            const authObj = await Auth.currentAuthenticatedUser();
            this.store.dispatch(
                successfulCognitoLoginAction({
                    username: authObj.username,
                    email: authObj.signInUserSession.idToken.payload.email,
                    cognitoToken: authObj.signInUserSession.idToken.jwtToken
                }),
            );

            return true;
        } catch {
            if (forceLogin) {
                const signIn = await Auth.federatedSignIn(
                    forceSSO ? { customProvider: environment.cognito.customProviderName } : undefined,
                );
            }
            return false;
        }
    }

  public fetchMapToken(cognitoToken: string, email: string): Observable<TokenResponse> {
    return this.get<TokenResponse>(`token/${email}`, {
      headers: {"Authorization": `Bearer ${cognitoToken}`}
    });
  }
}
